<template>
  <div class="detail-page">
    <div class="detail-page__left"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['menu_InsuranceSkladUsa',])"></div>
      <CardLeftBlock
          :name="$t('menu_InsuranceSkladUsa.localization_value.value')"
          :value="'#' + ISU.data.id"
          :backgroundImage="'customs-info'"
      >
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head">
            <div class="order-create__head-line">
              <div class="order-create__header-col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['insuranceSkladUsa_BackToList',])"></div>
                <router-link :to="$store.getters.GET_PATHS.mainSettingsInsuranceSkladUsa" class="order-create__head-back">
                  <LinkBack
                      :value="$t('insuranceSkladUsa_BackToList.localization_value.value')"
                      @click.native="goRouterBack()"
                  />
                </router-link>
              </div>
            </div>
            <div class="order-create__head-title"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['menu_InsuranceSkladUsa',])"></div>
              {{$t('menu_InsuranceSkladUsa.localization_value.value')}}
            </div>
          </div>
        </template>

        <template slot="body">
          <div class="order-create__section">

            <div class="custom-row">
              <div class="custom-col custom-col--33 custom-col--sm-100">
                <div class="order-create__section-label section-label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['insuranceSkladUsa_name'])"></div>
                  {{ $t('insuranceSkladUsa_name.localization_value.value') }}
                </div>
                <div class="custom-row">
                  <div class="order-create__col custom-col">{{ ISU.data.name }}</div>
                </div>
              </div>
              <div class="custom-col custom-col--33 custom-col--sm-100">
                <div class="order-create__section-label section-label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['insuranceSkladUsa_price'])"></div>
                  {{ $t('insuranceSkladUsa_price.localization_value.value') }}
                </div>
                <div class="custom-row">
                  <div class="order-create__col custom-col">
                    $ {{ ISU.data.price }}
                  </div>
                </div>
              </div>
            </div>

            <div class="custom-row">
              <div class="custom-col custom-col--33 custom-col--sm-100">
                <div class="order-create__section-label section-label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['insuranceSkladUsa_countryGroup'])"></div>
                  {{ $t('insuranceSkladUsa_countryGroup.localization_value.value') }}
                </div>
                <div class="custom-row">
                  <div class="order-create__col custom-col">
                    <ValueHelper
                            :value="ISU.data.country"
                            :deep="'name'"
                    />
                  </div>
                </div>
              </div>
              <div class="custom-col custom-col--33 custom-col--sm-100">
                <div class="order-create__section-label section-label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['insuranceSkladUsa_shippingCompany'])"></div>
                  {{ $t('insuranceSkladUsa_shippingCompany.localization_value.value') }}
                </div>
                <div class="custom-row">
                  <div class="order-create__col custom-col">
                    <ValueHelper
                            :value="ISU.data.shippingCompany"
                            :deep="'name'"
                    />
                  </div>
                </div>
              </div>
            </div>

          </div>
        </template>
      </CardRightBlock>

    </div>
  </div>
</template>

<script>
  import {InsuranceSkladUsa} from "../../models/InsuranceSkladUsa";
  import CardLeftBlock from "../../../../coreComponents/CardLeftBlock/CardLeftBlock";
  import CardRightBlock from "../../../../coreComponents/CardRightBlock/CardRightBlock";
  import LinkBack from "../../../../UI/buttons/LinkBack/LinkBack";
  import ValueHelper from "../../../../coreComponents/ValueHelper/ValueHelper";

  export default {
    name: "InsuranceSkladUsaShow",

    components:{
      ValueHelper,
      LinkBack,
      CardRightBlock,
      CardLeftBlock

    },

    data() {
      return {
        ISU: new InsuranceSkladUsa(),
      }
    },

    computed: {
      getCountryName() {
        return this.ISU.getCountry()?.currentTranslate?.name || ''
      }
    },

    mounted() {
      this.initInsuranceSkladUsaShow()
    },

    methods: {
      initInsuranceSkladUsaShow() {
          this.ISU.setId(this.$route.params.id)

          this.$store.dispatch('getInsuranceSkladUsa', this.ISU.getId()).then((response) => {
            let item = this.getRespData(response)
            this.ISU.setItem(item, this)
          }).catch(error => this.createErrorLog(error))
      },

    }

  }
</script>

<style scoped>

</style>
